const IconClose = () => (
    <svg viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.634 9.908a1 1 0 1 0 1.415-1.414L6.657 5.1l3.39-3.393A1 1 0 0 0 8.634.293l-3.39 3.392L1.853.293A1 1 0 1 0 .438 1.707L3.83 5.1.437 8.494a1 1 0 0 0 1.415 1.414l3.391-3.393 3.391 3.393z"
            fill="currentColor"
        />
    </svg>
);

export default IconClose;
